import React from 'react';
import styles from './App.module.css';
import Footer from '../Footer/Footer';
import Main from '../Main/Main';

export default function App() {
  return (
    <div className={styles.app}>
      <Main />
      <Footer />
    </div>
  );
}
