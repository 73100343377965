import React from 'react';
import styles from './Modals.module.css';

export default function FirstQuestionModal({ openSecond }) {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="34"
            viewBox="0 0 35 34"
            fill="none"
          >
            <path
              d="M6.16666 18.4167L9.20087 21.4509C10.6182 22.8682 11.3269 23.5769 12.144 23.8424C12.8628 24.0759 13.6371 24.0759 14.3559 23.8424C15.1731 23.5769 15.8818 22.8682 17.2991 21.4509L30.25 8.5"
              stroke="#E9FFFF"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            className={styles.bubbles}
          >
            <path
              d="M38.973 60.1315C40.7808 60.4473 42.0033 62.1875 41.6875 63.9953C41.3716 65.8031 39.6314 67.0256 37.8236 66.7098C36.0159 66.3939 34.7933 64.6537 35.1092 62.8459C35.4294 61.013 37.1401 59.8112 38.973 60.1315Z"
              fill="#3BEDDC"
            />
            <path
              d="M27.4395 65.243C31.5353 65.5439 33.2833 61.8845 29.3143 59.3235C25.7164 56.9902 20.5277 51.8819 16.6294 45.2065C14.1099 40.9273 7.31161 45.1913 10.438 52.3661C11.8213 55.5422 19.8429 64.6528 27.4395 65.243Z"
              fill="#3BEDDC"
            />
            <path
              opacity="0.43"
              d="M51.6961 18.9417C58.4564 25.038 57.115 36.6617 63.0635 35.3385C68.0746 34.2001 65.4289 20.0588 57.481 12.7786C50.8762 6.75355 37.1711 4.94696 36.9438 11.0498C36.771 15.7482 44.7252 12.6486 51.6961 18.9417Z"
              fill="#F9FFFF"
            />
          </svg>
        </div>

        <h2 className={styles.title}>Верно, вы молодец!</h2>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.image}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  d="M9 21.9443V28.4562C9 30.8583 9.16758 33.2603 9.44689 35.6065L10.1172 39.7402C10.1172 40.2988 11.9048 40.6898 14.0834 40.6898C16.262 40.6898 18.0495 40.243 18.0495 39.7402L18.6081 35.6065C18.9433 33.2044 19.055 30.8583 19.055 28.4562V21.9443"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M10.2289 21.4415C9.44689 21.6091 9 21.7766 9 22.0001C9 22.3352 10.2289 22.6704 12.0165 22.7821L12.2958 23.8435H15.9268L16.2061 22.7821C17.8819 22.6704 19.055 22.3352 19.055 22.0001C19.055 21.7766 18.6081 21.6091 17.8261 21.4415"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M14.25 9.75C16.3211 9.75 18 9.24632 18 8.625C18 8.00368 16.3211 7.5 14.25 7.5C12.1789 7.5 10.5 8.00368 10.5 8.625C10.5 9.24632 12.1789 9.75 14.25 9.75Z"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M18 8.60312C18 13.1367 18 14.9285 18 19.4621C18 19.9348 16.3269 20.25 14.25 20.25C12.1731 20.25 10.5 19.9348 10.5 19.4621V8.60312"
                  stroke="#34303D"
                  stroke-width="1.7"
                />
                <path
                  d="M21.65 23.262C21.65 24.3141 22.5236 25.1877 23.5757 25.1877C24.6502 25.1877 25.5014 24.2913 25.5014 23.2171V12.3257C25.5014 11.2736 24.6278 10.4 23.5757 10.4C22.5236 10.4 21.65 11.2736 21.65 12.3257V23.262Z"
                  fill="white"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M23.5757 25.8376V35.2948"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M35.2485 23.262C35.2485 24.3141 36.1221 25.1877 37.1742 25.1877C38.2487 25.1877 39.0999 24.2913 39.0999 23.2171V12.3257C39.0999 11.2736 38.2263 10.4 37.1742 10.4C36.1221 10.4 35.2485 11.2736 35.2485 12.3257V23.262Z"
                  fill="white"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M37.1741 25.8376V35.2948"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M28.4718 23.262C28.4718 24.3141 29.3454 25.1877 30.3975 25.1877C31.472 25.1877 32.3232 24.2913 32.3232 23.2171V12.3257C32.3232 11.2736 31.4496 10.4 30.3975 10.4C29.3454 10.4 28.4718 11.2736 28.4718 12.3257V23.262Z"
                  fill="white"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M30.3975 25.8376V36.9532"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <p className={styles.text}>
              Ваши незаменимые помощники для чистки - устройство для чистки и
              чистящие палочки
            </p>
          </li>
          <li className={styles.item}>
            <div className={styles.image}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="66"
                height="66"
                viewBox="0 0 70 70"
                fill="none"
              >
                <g filter="url(#filter0_ii_304_192)">
                  <circle cx="36" cy="36" r="34" fill="#2EE1D6" />
                </g>
                <circle
                  cx="36"
                  cy="36"
                  r="33.5405"
                  stroke="url(#paint0_linear_304_192)"
                  stroke-width="0.918919"
                />
                <g filter="url(#filter1_f_304_192)">
                  <circle
                    cx="36"
                    cy="36"
                    r="33.5854"
                    stroke="url(#paint1_linear_304_192)"
                    stroke-width="0.829268"
                  />
                </g>
                <path
                  d="M36.4134 27.5645L36.4132 27.5644C36.1442 27.2867 35.6699 27.2867 35.4008 27.5644L35.4007 27.5645C35.2143 27.7573 32.9526 30.1119 30.7363 33.0572C29.6283 34.5297 28.5285 36.154 27.705 37.7325C26.8839 39.3065 26.3261 40.8557 26.3261 42.1727C26.3261 47.4873 30.6231 51.8127 35.9071 51.8127C41.191 51.8127 45.488 47.4873 45.488 42.1727C45.488 40.8557 44.9302 39.3065 44.1091 37.7325C43.2856 36.154 42.1858 34.5297 41.0778 33.0572C38.8615 30.1119 36.5998 27.7573 36.4134 27.5645ZM35.9071 50.3944C31.4038 50.3944 27.7378 46.7071 27.7378 42.1727C27.7378 41.1908 28.14 39.9873 28.7887 38.6919C29.4352 37.4008 30.3165 36.0376 31.2532 34.7418C33.0323 32.2805 35.0002 30.0766 35.9071 29.0931C36.8141 30.0763 38.7821 32.2798 40.5611 34.7409C41.4977 36.0366 42.379 37.3997 43.0255 38.691C43.6741 39.9865 44.0763 41.1903 44.0763 42.1727C44.0763 46.7071 40.4102 50.3944 35.9071 50.3944Z"
                  fill="#34303D"
                  stroke="#34303D"
                  stroke-width="0.367568"
                />
                <path
                  d="M24.7785 22.2622L24.7784 22.2621C24.5411 22.0171 24.1212 22.0171 23.8838 22.2621L23.8837 22.2622C23.6504 22.5035 22.5148 23.6968 21.4367 25.1527C20.8977 25.8805 20.3707 26.6769 19.978 27.455C19.5868 28.2302 19.3216 29.0009 19.3216 29.6736C19.3216 32.4519 21.5678 34.7135 24.3311 34.7135C27.0944 34.7135 29.3406 32.4519 29.3406 29.6736C29.3406 29.0009 29.0755 28.2302 28.6842 27.455C28.2915 26.6769 27.7646 25.8805 27.2256 25.1527C26.1474 23.6968 25.0118 22.5035 24.7785 22.2622ZM24.3311 33.4599C22.2579 33.4599 20.5691 31.7619 20.5691 29.6736C20.5691 29.2854 20.7186 28.7981 20.9763 28.2529C21.2326 27.7105 21.5897 27.1237 21.9908 26.5403C22.7533 25.4313 23.6678 24.3447 24.3312 23.6146C24.9947 24.3444 25.9092 25.4306 26.6716 26.5395C27.0727 27.1228 27.4297 27.7096 27.686 28.2521C27.9437 28.7974 28.0931 29.285 28.0931 29.6736C28.0931 31.7619 26.4044 33.4599 24.3311 33.4599Z"
                  fill="#34303D"
                  stroke="#34303D"
                  stroke-width="0.275676"
                />
                <path
                  d="M46.535 27.7807C46.1762 28.1395 46.1762 28.7214 46.535 29.0802L50.135 32.6802C50.4939 33.0391 50.4939 33.6209 50.135 33.9798L49.6016 34.5132C49.2427 34.8721 48.6607 34.872 48.3019 34.513L44.7049 30.9136C44.3462 30.5546 43.7644 30.5545 43.4054 30.9132L39.7705 34.5457C39.4116 34.9043 38.8299 34.9042 38.4711 34.5455L37.8737 33.948C37.5148 33.5891 37.5148 33.0073 37.8737 32.6484L41.5055 29.0166C41.8644 28.6577 41.8644 28.0759 41.5055 27.7171L37.9055 24.117C37.5466 23.7582 37.5466 23.1764 37.9055 22.8175L38.4391 22.2839C38.798 21.925 39.3798 21.925 39.7387 22.2839L43.3387 25.8839C43.6975 26.2427 44.2794 26.2427 44.6382 25.8839L48.27 22.252C48.6289 21.8932 49.2107 21.8932 49.5696 22.252L50.1669 22.8493C50.5257 23.2082 50.5257 23.79 50.1669 24.1489L46.535 27.7807Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className={styles.text}>
              Не используйте воду и другие жидкости во время чистки IQOS
            </p>
          </li>
        </ul>
      </div>
      <button className={styles.button} onClick={openSecond}>
        <p className={styles.buttonText}>Далее</p>
      </button>
    </div>
  );
}
