import React, { useState } from 'react';
import styles from './ThierdQuestion.module.css';
import ThierdQuestionModal from '../Modals/ThierdQuestionModal';
import ThierdQuestionErrorModal from '../Modals/ThierdQuestionErrorModal';
import { sendEventAnalitic } from '../../utils/api';

export default function ThierdQuestions({ openFinal }) {
  const [firstAnswer, setFirstAnswer] = useState(false);
  const [secondAnswer, setSecondAnswer] = useState(false);
  const [thierdAnswer, setThierdAnswer] = useState(false);
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [green, setGreen] = useState(false);
  const [red, setRed] = useState(false);
  const [red1, setRed1] = useState(false);

  const getStyle = () => {
    if (green) {
      return styles.green;
    } else if (thierdAnswer) {
      return styles.activeAnswerContainer;
    } else {
      return styles.answerContainer;
    }
  };
  const getStyle1 = () => {
    if (red) {
      return styles.red;
    } else if (firstAnswer) {
      return styles.activeAnswerContainer;
    } else {
      return styles.answerContainer;
    }
  };
  const getStyle2 = () => {
    if (red1) {
      return styles.red;
    } else if (secondAnswer) {
      return styles.activeAnswerContainer;
    } else {
      return styles.answerContainer;
    }
  };

  const handlefirstAnswer = () => {
    setFirstAnswer(true);
    setSecondAnswer(false);
    setThierdAnswer(false);
  };

  const handleSecondAnswer = () => {
    setSecondAnswer(true);
    setFirstAnswer(false);
    setThierdAnswer(false);
  };
  const handleThierdAnswer = () => {
    setThierdAnswer(true);
    setFirstAnswer(false);
    setSecondAnswer(false);
  };

  const checkAnswer = () => {
    if (thierdAnswer) {
      setGreen(true);
      setTimeout(() => {
        setModal(true);
        setErrorModal(false);
      }, 2000);
    } else if (secondAnswer) {
      setRed1(true);

      setTimeout(() => {
        setErrorModal(true);
        setModal(false);
      }, 2000);
    } else if (firstAnswer) {
      setRed(true);

      setTimeout(() => {
        setErrorModal(true);
        setModal(false);
      }, 2000);
    }
  };
  return (
    <div className={styles.second}>
      <div>
        <div className={styles.navbar}>
          <div className={styles.nav} style={{ background: '#26D7EE' }}></div>
          <div className={styles.nav} style={{ background: '#26D7EE' }}></div>
          <div className={styles.nav} style={{ background: '#26D7EE' }}></div>
        </div>
        <h2 className={styles.title}>Зачем нужно чистить устройство?</h2>

        <button
          className={getStyle1()}
          onClick={() => {
            handlefirstAnswer();
          }}
        >
          <div className={red ? styles.redBubble : styles.bubble}>
            <p className={styles.number}>1</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              className={styles.bubbleShine}
            >
              <path
                d="M36.8661 55.9352C38.5761 56.2339 39.7326 57.8801 39.4338 59.5901C39.135 61.3002 37.4889 62.4567 35.7788 62.1579C34.0688 61.8591 32.9123 60.213 33.2111 58.5029C33.514 56.7691 35.1323 55.6322 36.8661 55.9352Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                d="M25.9566 60.7704C29.8309 61.055 31.4845 57.5934 27.73 55.1708C24.3266 52.9637 19.4183 48.1316 15.7308 41.817C13.3474 37.7691 6.91666 41.8026 9.87407 48.5896C11.1826 51.594 18.7706 60.2121 25.9566 60.7704Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                opacity="0.3"
                d="M48.9018 17.9179C55.2966 23.6846 54.0277 34.68 59.6547 33.4283C64.3949 32.3514 61.8923 18.9746 54.374 12.0879C48.1261 6.38852 35.1618 4.67958 34.9468 10.4526C34.7834 14.897 42.3076 11.9649 48.9018 17.9179Z"
                fill="#F9FFFF"
              />
            </svg>
          </div>
          <p className={red ? styles.redText : styles.answerText}>
            Для постоянства вкуса и ощущения
          </p>
        </button>
        <button
          className={getStyle2()}
          onClick={() => {
            handleSecondAnswer();
          }}
        >
          <div className={red1 ? styles.redBubble : styles.bubble}>
            <p className={styles.number}>2</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              className={styles.bubbleShine}
            >
              <path
                d="M36.8661 55.9352C38.5761 56.2339 39.7326 57.8801 39.4338 59.5901C39.135 61.3002 37.4889 62.4567 35.7788 62.1579C34.0688 61.8591 32.9123 60.213 33.2111 58.5029C33.514 56.7691 35.1323 55.6322 36.8661 55.9352Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                d="M25.9566 60.7704C29.8309 61.055 31.4845 57.5934 27.73 55.1708C24.3266 52.9637 19.4183 48.1316 15.7308 41.817C13.3474 37.7691 6.91666 41.8026 9.87407 48.5896C11.1826 51.594 18.7706 60.2121 25.9566 60.7704Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                opacity="0.3"
                d="M48.9018 17.9179C55.2966 23.6846 54.0277 34.68 59.6547 33.4283C64.3949 32.3514 61.8923 18.9746 54.374 12.0879C48.1261 6.38852 35.1618 4.67958 34.9468 10.4526C34.7834 14.897 42.3076 11.9649 48.9018 17.9179Z"
                fill="#F9FFFF"
              />
            </svg>
          </div>
          <p className={red1 ? styles.redText : styles.answerText}>
            Для образования оптимального кол-ва пара
          </p>
        </button>
        <button
          className={getStyle()}
          onClick={() => {
            handleThierdAnswer();
          }}
        >
          <div className={green ? styles.greenBubble : styles.bubble}>
            <p className={styles.number}>3</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              className={styles.bubbleShine}
            >
              <path
                d="M36.8661 55.9352C38.5761 56.2339 39.7326 57.8801 39.4338 59.5901C39.135 61.3002 37.4889 62.4567 35.7788 62.1579C34.0688 61.8591 32.9123 60.213 33.2111 58.5029C33.514 56.7691 35.1323 55.6322 36.8661 55.9352Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                d="M25.9566 60.7704C29.8309 61.055 31.4845 57.5934 27.73 55.1708C24.3266 52.9637 19.4183 48.1316 15.7308 41.817C13.3474 37.7691 6.91666 41.8026 9.87407 48.5896C11.1826 51.594 18.7706 60.2121 25.9566 60.7704Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                opacity="0.3"
                d="M48.9018 17.9179C55.2966 23.6846 54.0277 34.68 59.6547 33.4283C64.3949 32.3514 61.8923 18.9746 54.374 12.0879C48.1261 6.38852 35.1618 4.67958 34.9468 10.4526C34.7834 14.897 42.3076 11.9649 48.9018 17.9179Z"
                fill="#F9FFFF"
              />
            </svg>
          </div>
          <p className={green ? styles.greenText : styles.answerText}>
            Все вышеперечисленное
          </p>
        </button>
      </div>

      {red || red1 || green ? (
        <></>
      ) : (
        <button
          className={styles.button}
          onClick={() => {
            checkAnswer();
            sendEventAnalitic('passed_all_scenario');
          }}
        >
          <p className={styles.buttonText}>Ответить</p>
        </button>
      )}

      {modal && <ThierdQuestionModal openFinal={openFinal} />}
      {errorModal && <ThierdQuestionErrorModal openFinal={openFinal} />}
    </div>
  );
}
