import React, { useState } from 'react';
import styles from './Main.module.css';
import Guide from '../Guide/Guide';
import Tools from '../Tools/Tools';
import FirstQuestion from '../FirstQuestion/FirstQuestion';
import SecondQuestionIqos from '../SecondQuestionIqos/SecondQuestionIqos';
import ThierdQuestions from '../ThierdQuestion/ThierdQuestion';
import FinalScreen from '../FinalScreen/FinalScreen';

export default function Main() {
  const [guide, setGuide] = useState(true);
  const [tools, setTools] = useState(false);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);

  const [thierd, setThierd] = useState(false);
  const [final, setFinal] = useState(false);
  const [iqos, setIqos] = useState(false);

  const openTools = () => {
    setGuide(false);
    setTools(true);
  };

  const openFirst = () => {
    setTools(false);
    setFirst(true);
  };

  const openSecond = () => {
    setFirst(false);
    setSecond(true);
  };
  const openThierd = () => {
    setSecond(false);
    setThierd(true);
  };
  const openFinal = () => {
    setThierd(false);
    setFinal(true);
  };

  return (
    <div className={styles.main}>
      {guide && <Guide openTools={openTools} />}
      {tools && <Tools openFirst={openFirst} setIqos={setIqos} />}
      {first && <FirstQuestion openSecond={openSecond} />}
      {second && <SecondQuestionIqos openThierd={openThierd} iqos={iqos} />}
      {thierd && <ThierdQuestions openFinal={openFinal} />}
      {final && <FinalScreen />}
    </div>
  );
}
