import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './components/App/App';

console.log(process.env);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENV,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

if (typeof window !== 'undefined') {
  const tg = window.Telegram.WebApp;
  tg.expand();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
