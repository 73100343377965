import React from "react";
import styles from "./FinalScreen.module.css";

export default function FinalScreen() {
  function closeApp() {
    const tg = window.Telegram.WebApp;
    tg.close();
    localStorage.clear();
  }

  return (
    <div className={styles.final}>
      <div className={styles.container}>
        <div className={styles.navbar}>
          <div className={styles.nav} style={{ background: "#26D7EE" }}></div>
          <div className={styles.nav} style={{ background: "#26D7EE" }}></div>
          <div className={styles.nav} style={{ background: "#26D7EE" }}></div>
        </div>
        <h2 className={styles.title}>Отличная работа</h2>
        <p className={styles.text}>Теперь вы больше знаете о важности чистки</p>
      </div>
      <button className={styles.button} onClick={closeApp}>
        <p className={styles.buttonText}>Отлично!</p>
      </button>
    </div>
  );
}
