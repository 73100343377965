import React from 'react';
import styles from './Modals.module.css';

export default function SecondQuestionModal({ openThierd }) {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="19"
            viewBox="0 0 28 19"
            fill="none"
          >
            <path
              d="M2.1665 11.4167L5.20072 14.4509C6.61804 15.8682 7.32671 16.5769 8.14388 16.8424C8.86269 17.0759 9.63698 17.0759 10.3558 16.8424C11.173 16.5769 11.8816 15.8682 13.299 14.4509L26.2498 1.5"
              stroke="#E9FFFF"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="74"
            viewBox="0 0 75 74"
            fill="none"
            className={styles.bubbles}
          >
            <path
              d="M39.4729 59.1315C41.2807 59.4473 42.5033 61.1875 42.1874 62.9953C41.8716 64.8031 40.1314 66.0256 38.3236 65.7098C36.5158 65.3939 35.2932 63.6537 35.6091 61.8459C35.9293 60.013 37.6401 58.8112 39.4729 59.1315Z"
              fill="#FB748C"
              fill-opacity="0.3"
            />
            <path
              d="M27.9395 64.243C32.0353 64.5439 33.7834 60.8845 29.8143 58.3235C26.2165 55.9902 21.0277 50.8819 17.1294 44.2065C14.6099 39.9274 7.81164 44.1913 10.938 51.3661C12.3214 54.5422 20.3429 63.6528 27.9395 64.243Z"
              fill="#FB748C"
              fill-opacity="0.3"
            />
            <path
              opacity="0.25"
              d="M52.1961 18.9417C58.9564 25.038 57.615 36.6617 63.5635 35.3385C68.5746 34.2001 65.9289 20.0588 57.981 12.7786C51.3762 6.75355 37.6711 4.94696 37.4438 11.0498C37.271 15.7482 45.2252 12.6486 52.1961 18.9417Z"
              fill="#F9FFFF"
            />
          </svg>
        </div>

        <h2 className={styles.title}>Верно, вы молодец!</h2>
        <ul className={styles.secondList}>
          <li className={styles.text}>
            Вставьте устройство для чистки в держатель до упора и аккуратно
            покрутите
          </li>
          <li className={styles.text}>Вытряхните остатки табака</li>
          <li className={styles.text}>
            Протрите палочкой для чистки колпачок и внутренние стенки держателя,
            не задевая нагревательный элемент
          </li>
        </ul>
      </div>
      <button className={styles.button} onClick={openThierd}>
        <p className={styles.buttonText}>Далее</p>
      </button>
    </div>
  );
}
