import React from 'react';
import styles from './Modals.module.css';

export default function ThierdQuestionErrorModal({ openFinal }) {
  return (
    <div className={styles.overlay}>
      <div className={styles.errorContainer}>
        <div className={styles.errorIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
          >
            <path
              d="M8.5 8.5L25.5 25.5"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M25.5 8.5L8.5 25.5"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="74"
            viewBox="0 0 75 74"
            fill="none"
            className={styles.bubbles}
          >
            <path
              d="M39.4729 59.1315C41.2807 59.4473 42.5033 61.1875 42.1874 62.9953C41.8716 64.8031 40.1314 66.0256 38.3236 65.7098C36.5158 65.3939 35.2932 63.6537 35.6091 61.8459C35.9293 60.013 37.6401 58.8112 39.4729 59.1315Z"
              fill="#FB748C"
              fill-opacity="0.3"
            />
            <path
              d="M27.9395 64.243C32.0353 64.5439 33.7834 60.8845 29.8143 58.3235C26.2165 55.9902 21.0277 50.8819 17.1294 44.2065C14.6099 39.9274 7.81164 44.1913 10.938 51.3661C12.3214 54.5422 20.3429 63.6528 27.9395 64.243Z"
              fill="#FB748C"
              fill-opacity="0.3"
            />
            <path
              opacity="0.25"
              d="M52.1961 18.9417C58.9564 25.038 57.615 36.6617 63.5635 35.3385C68.5746 34.2001 65.9289 20.0588 57.981 12.7786C51.3762 6.75355 37.6711 4.94696 37.4438 11.0498C37.271 15.7482 45.2252 12.6486 52.1961 18.9417Z"
              fill="#F9FFFF"
            />
          </svg>
        </div>

        <h2 className={styles.title}>Вы были близко, оба варианта верные</h2>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.image}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="40"
                fill="none"
              >
                <path
                  fill="#F9F9F9"
                  d="m25.174 2.374-2.31-.794c-.614 0-1.551 1.292-1.551 1.907v21.82c0 .614.498 1.113 1.113 1.113h2.748c.615 0 1.455-.49 1.455-1.105l.062-20.182c0-.615-.902-2.76-1.517-2.76Z"
                />
                <path
                  stroke="#34303D"
                  stroke-width="1.6"
                  d="M1.328 38.572 1.18 7.889l6.858-.557c.915 0 1.744.37 2.343.971.601.599.971 1.429.971 2.344v23.282c0 1.75.541 3.301 1.651 4.643H1.328Z"
                  clip-rule="evenodd"
                />
                <path
                  stroke="#34303D"
                  stroke-width="1.6"
                  d="M1 15.64h10.78m2.517 22.933a2.77 2.77 0 0 1-1.968-.816 2.77 2.77 0 0 1-.816-1.968V3.784c0-.769.312-1.465.816-1.969A2.774 2.774 0 0 1 14.297 1h7.137c1.537 0 2.928.624 3.936 1.63A5.55 5.55 0 0 1 27 6.567v29.78c0 .614-.248 1.17-.651 1.574a2.22 2.22 0 0 1-1.576.652H14.297Z"
                />
                <path
                  fill="#F9F9F9"
                  d="M6.182 12.816a1.113 1.113 0 1 0 0-2.227 1.113 1.113 0 0 0 0 2.227Z"
                />
                <path
                  stroke="#34303D"
                  stroke-width="1.6"
                  d="M22.652 38.576a2.487 2.487 0 0 1-1.339-2.206V4.032A2.488 2.488 0 0 1 23.8 1.544"
                />
                <path
                  stroke="#34303D"
                  stroke-linecap="square"
                  stroke-width="1.6"
                  d="M21.729 26.004h4.975"
                />
              </svg>
            </div>
            <p className={styles.text}>
              Чистота устройства обеспечивает образование оптимального
              количества табачного пара
            </p>
          </li>
          <li className={styles.item}>
            <div className={styles.image}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="39"
                fill="none"
              >
                <path
                  stroke="#34303D"
                  stroke-miterlimit="10"
                  stroke-width="1.423"
                  d="M21.867 13.778C26.465 7.202 38.11 5.271 38.11 5.271s2.627 8.716.824 15.665a20.218 20.218 0 0 1-2.01 4.472c-2.44 3.832-6.107 4.897-9.45 4.178"
                />
                <path
                  stroke="#34303D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.423"
                  d="M34.323 11.48 24.126 28.202"
                />
                <path
                  stroke="#34303D"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  stroke-width="1.423"
                  d="m30.88 13.459.953 2.113M27.183 15.648l1.924 4.303M23.703 18.181l2.741 6.139M34.08 15.471l-2.289.074M33.783 19.822l-4.64.153M33.096 24.124l-6.615.217"
                />
                <path
                  fill="#fff"
                  stroke="#34303D"
                  stroke-width="1.423"
                  d="M13.1 34.256a10.004 10.004 0 0 1-2.041-1.429 8.545 8.545 0 0 1-2.404-.763c-3.542-1.749-5.365-7.591-2.44-11.917 3.197-4.733 8.669-6.44 13.306-4.155a7.649 7.649 0 0 1 2 1.429 8.665 8.665 0 0 1 2.445.774c4.351 2.131 5.438 7.477 2.442 11.904-2.925 4.331-9.76 5.906-13.308 4.157Z"
                />
                <path
                  stroke="#34303D"
                  stroke-width="1.423"
                  d="M12.95 23.466c-1.142 1.69-.791 3.83.676 5.629 2.557.185 4.904-.626 6.045-2.314 1.141-1.688.778-3.83-.677-5.628-2.557-.185-4.901.626-6.045 2.313Z"
                />
                <path
                  stroke="#34303D"
                  stroke-width="1.423"
                  d="M13.62 29.084a10.197 10.197 0 0 0 3.89-3.367 8.272 8.272 0 0 0 1.47-4.564"
                />
                <path
                  stroke="#34303D"
                  stroke-width="1.423"
                  d="M4.887 26.163c0 3.117 1.216 6.107 3.38 8.311a11.434 11.434 0 0 0 8.16 3.443c3.06 0 5.996-1.239 8.16-3.443a11.864 11.864 0 0 0 3.38-8.31"
                />
                <path
                  stroke="#34303D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.423"
                  d="M22.352 17.233c0-5.713-3.734-10.343-8.153-10.343-.857 0-1.705.168-2.5.494a6.551 6.551 0 0 0-2.505-.502c-5.06 0-8.15 4.63-8.15 10.343 0 3.199 1.485 6.056 3.391 7.953"
                />
                <path
                  stroke="#34303D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.423"
                  d="M14.646 9.603a8.103 8.103 0 0 0-2.594-2.04M7.465 10.11c-.916.348-1.836 1.194-2.282 3.077M18.59 1h-2.044c-.868.002-1.7.355-2.313.98a3.37 3.37 0 0 0-.957 2.359v2.08h2.043a3.248 3.248 0 0 0 2.313-.98 3.37 3.37 0 0 0 .957-2.358V1ZM11.909 7.445V3.97"
                />
              </svg>
            </div>
            <p className={styles.text}>
              Чистота устройства может влиять на вкус и ощущения во время сеанса
            </p>
          </li>
        </ul>
      </div>
      <button className={styles.button} onClick={openFinal}>
        <p className={styles.buttonText}>Далее</p>
      </button>
    </div>
  );
}
