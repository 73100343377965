import React from 'react';
import styles from './Modals.module.css';

export default function FirstQuestionErrorModal({ openSecond }) {
  return (
    <div className={styles.overlay}>
      <div className={styles.errorContainer}>
        <div className={styles.errorIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
          >
            <path
              d="M8.5 8.5L25.5 25.5"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M25.5 8.5L8.5 25.5"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="74"
            viewBox="0 0 75 74"
            fill="none"
            className={styles.bubbles}
          >
            <path
              d="M39.4729 59.1315C41.2807 59.4473 42.5033 61.1875 42.1874 62.9953C41.8716 64.8031 40.1314 66.0256 38.3236 65.7098C36.5158 65.3939 35.2932 63.6537 35.6091 61.8459C35.9293 60.013 37.6401 58.8112 39.4729 59.1315Z"
              fill="#FB748C"
              fill-opacity="0.3"
            />
            <path
              d="M27.9395 64.243C32.0353 64.5439 33.7834 60.8845 29.8143 58.3235C26.2165 55.9902 21.0277 50.8819 17.1294 44.2065C14.6099 39.9274 7.81164 44.1913 10.938 51.3661C12.3214 54.5422 20.3429 63.6528 27.9395 64.243Z"
              fill="#FB748C"
              fill-opacity="0.3"
            />
            <path
              opacity="0.25"
              d="M52.1961 18.9417C58.9564 25.038 57.615 36.6617 63.5635 35.3385C68.5746 34.2001 65.9289 20.0588 57.981 12.7786C51.3762 6.75355 37.6711 4.94696 37.4438 11.0498C37.271 15.7482 45.2252 12.6486 52.1961 18.9417Z"
              fill="#F9FFFF"
            />
          </svg>
        </div>

        <h2 className={styles.title}>Упс! На самом деле:</h2>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.image}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  d="M9 21.9443V28.4562C9 30.8583 9.16758 33.2603 9.44689 35.6065L10.1172 39.7402C10.1172 40.2988 11.9048 40.6898 14.0834 40.6898C16.262 40.6898 18.0495 40.243 18.0495 39.7402L18.6081 35.6065C18.9433 33.2044 19.055 30.8583 19.055 28.4562V21.9443"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M10.2289 21.4415C9.44689 21.6091 9 21.7766 9 22.0001C9 22.3352 10.2289 22.6704 12.0165 22.7821L12.2958 23.8435H15.9268L16.2061 22.7821C17.8819 22.6704 19.055 22.3352 19.055 22.0001C19.055 21.7766 18.6081 21.6091 17.8261 21.4415"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M14.25 9.75C16.3211 9.75 18 9.24632 18 8.625C18 8.00368 16.3211 7.5 14.25 7.5C12.1789 7.5 10.5 8.00368 10.5 8.625C10.5 9.24632 12.1789 9.75 14.25 9.75Z"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M18 8.60312C18 13.1367 18 14.9285 18 19.4621C18 19.9348 16.3269 20.25 14.25 20.25C12.1731 20.25 10.5 19.9348 10.5 19.4621V8.60312"
                  stroke="#34303D"
                  stroke-width="1.7"
                />
                <path
                  d="M21.65 23.262C21.65 24.3141 22.5236 25.1877 23.5757 25.1877C24.6502 25.1877 25.5014 24.2913 25.5014 23.2171V12.3257C25.5014 11.2736 24.6278 10.4 23.5757 10.4C22.5236 10.4 21.65 11.2736 21.65 12.3257V23.262Z"
                  fill="white"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M23.5757 25.8376V35.2948"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M35.2485 23.262C35.2485 24.3141 36.1221 25.1877 37.1742 25.1877C38.2487 25.1877 39.0999 24.2913 39.0999 23.2171V12.3257C39.0999 11.2736 38.2263 10.4 37.1742 10.4C36.1221 10.4 35.2485 11.2736 35.2485 12.3257V23.262Z"
                  fill="white"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M37.1741 25.8376V35.2948"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M28.4718 23.262C28.4718 24.3141 29.3454 25.1877 30.3975 25.1877C31.472 25.1877 32.3232 24.2913 32.3232 23.2171V12.3257C32.3232 11.2736 31.4496 10.4 30.3975 10.4C29.3454 10.4 28.4718 11.2736 28.4718 12.3257V23.262Z"
                  fill="white"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M30.3975 25.8376V36.9532"
                  stroke="#34303D"
                  stroke-width="1.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <p className={styles.text}>
              Ваши незаменимые помощники для чистки - устройство для чистки и
              чистящие палочки
            </p>
          </li>
          <li className={styles.item}>
            <div className={styles.image}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="36"
                fill="none"
              >
                <path
                  fill="#34303D"
                  stroke="#34303D"
                  stroke-width=".368"
                  d="M17.413 11.565a.72.72 0 0 0-1.012 0 64.585 64.585 0 0 0-4.665 5.492c-1.108 1.473-2.207 3.097-3.03 4.676-.822 1.573-1.38 3.123-1.38 4.44 0 5.314 4.297 9.64 9.581 9.64s9.581-4.326 9.581-9.64c0-1.317-.558-2.866-1.379-4.44-.823-1.579-1.923-3.203-3.031-4.676a64.585 64.585 0 0 0-4.665-5.492Zm-.506 22.83c-4.503 0-8.17-3.688-8.17-8.222 0-.982.403-2.186 1.052-3.481.646-1.291 1.528-2.654 2.464-3.95 1.78-2.462 3.747-4.665 4.654-5.649.907.983 2.875 3.187 4.654 5.648.937 1.296 1.818 2.659 2.465 3.95.648 1.296 1.05 2.5 1.05 3.482 0 4.534-3.666 8.221-8.169 8.221Z"
                />
                <path
                  fill="#34303D"
                  stroke="#34303D"
                  stroke-width=".276"
                  d="M5.779 6.262a.636.636 0 0 0-.895 0 31.284 31.284 0 0 0-2.447 2.89c-.54.728-1.066 1.525-1.459 2.303-.391.775-.656 1.546-.656 2.219 0 2.778 2.246 5.04 5.01 5.04 2.762 0 5.009-2.262 5.009-5.04 0-.673-.265-1.444-.657-2.219-.392-.778-.92-1.575-1.458-2.302a31.284 31.284 0 0 0-2.447-2.89ZM5.33 17.46c-2.073 0-3.762-1.698-3.762-3.786 0-.389.15-.876.407-1.421.257-.543.614-1.13 1.015-1.713a29.255 29.255 0 0 1 2.34-2.925c.664.73 1.578 1.816 2.34 2.925.402.583.759 1.17 1.015 1.712.258.545.407 1.033.407 1.422 0 2.088-1.689 3.786-3.762 3.786Z"
                />
                <path
                  fill="#fff"
                  d="M27.535 11.78a.919.919 0 0 0 0 1.3l3.6 3.6a.919.919 0 0 1 0 1.3l-.533.533a.919.919 0 0 1-1.3 0l-3.597-3.6a.919.919 0 0 0-1.3 0l-3.635 3.633a.92.92 0 0 1-1.299 0l-.597-.598a.919.919 0 0 1 0-1.3l3.632-3.631a.919.919 0 0 0 0-1.3l-3.6-3.6a.919.919 0 0 1 0-1.3l.533-.533a.919.919 0 0 1 1.3 0l3.6 3.6a.919.919 0 0 0 1.3 0l3.631-3.632a.919.919 0 0 1 1.3 0l.597.597a.919.919 0 0 1 0 1.3l-3.632 3.632Z"
                />
              </svg>
            </div>
            <p className={styles.text}>
              Не используйте воду и другие жидкости во время чистки IQOS
            </p>
          </li>
        </ul>
      </div>
      <button className={styles.button} onClick={openSecond}>
        <p className={styles.buttonText}>Далее</p>
      </button>
    </div>
  );
}
