import React, { useState } from 'react';
import styles from './FirstQuestion.module.css';
import FirstQuestionModal from '../Modals/FirstQuestionModal';
import FirstQuestionErrorModal from '../Modals/FirstQuestionErrorModal';
import icon from '../../images/icon.svg';

export default function FirstQuestion({ openSecond }) {
  const [firstAnswer, setFirstAnswer] = useState(false);
  const [secondAnswer, setSecondAnswer] = useState(false);
  const [thierdAnswer, setThierdAnswer] = useState(false);
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [green, setGreen] = useState(false);
  const [red, setRed] = useState(false);
  const [red1, setRed1] = useState(false);

  const getStyle = () => {
    if (green) {
      return styles.green;
    } else if (firstAnswer) {
      return styles.activeAnswerContainer;
    } else {
      return styles.answerContainer;
    }
  };
  const getStyle1 = () => {
    if (red) {
      return styles.red;
    } else if (secondAnswer) {
      return styles.activeAnswerContainer;
    } else {
      return styles.answerContainer;
    }
  };
  const getStyle2 = () => {
    if (red1) {
      return styles.red;
    } else if (thierdAnswer) {
      return styles.activeAnswerContainer;
    } else {
      return styles.answerContainer;
    }
  };

  const handlefirstAnswer = () => {
    setFirstAnswer(true);
    setSecondAnswer(false);
    setThierdAnswer(false);
  };

  const handleSecondAnswer = () => {
    setSecondAnswer(true);
    setFirstAnswer(false);
    setThierdAnswer(false);
  };
  const handleThierdAnswer = () => {
    setThierdAnswer(true);
    setFirstAnswer(false);
    setSecondAnswer(false);
  };

  const checkAnswer = () => {
    if (firstAnswer) {
      setGreen(true);
      setTimeout(() => {
        setModal(true);
        setErrorModal(false);
      }, 2000);
    } else if (secondAnswer) {
      setRed(true);

      setTimeout(() => {
        setErrorModal(true);
        setModal(false);
      }, 2000);
    } else if (thierdAnswer) {
      setRed1(true);

      setTimeout(() => {
        setErrorModal(true);
        setModal(false);
      }, 2000);
    }
  };

  return (
    <div className={styles.first}>
      <div>
        <div className={styles.navbar}>
          <div className={styles.nav} style={{ background: '#26D7EE' }}></div>
          <div className={styles.nav}></div>
          <div className={styles.nav}></div>
        </div>
        <h2 className={styles.title}>
          Чем нужно чистить устройство для лучшего результата?
        </h2>

        <button
          className={getStyle()}
          onClick={() => {
            handlefirstAnswer();
          }}
        >
          <div className={green ? styles.greenBubble : styles.bubble}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              className={styles.icon}
            >
              <path
                d="M9.62842 22.8933V29.5666C9.62842 32.0282 9.80015 34.4897 10.0864 36.894L10.7733 41.1302C10.7733 41.7026 12.6052 42.1033 14.8377 42.1033C17.0703 42.1033 18.9021 41.6454 18.9021 41.1302L19.4746 36.894C19.8181 34.4325 19.9326 32.0282 19.9326 29.5666V22.8933"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
              />
              <path
                d="M10.8878 22.3781C10.0864 22.5498 9.62842 22.7216 9.62842 22.9505C9.62842 23.294 10.8878 23.6375 12.7197 23.752L13.0059 24.8396H16.7268L17.013 23.752C18.7304 23.6375 19.9326 23.294 19.9326 22.9505C19.9326 22.7216 19.4746 22.5498 18.6732 22.3781"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
              />
              <path
                d="M15.0085 10.397C17.1309 10.397 18.8514 9.8808 18.8514 9.24409C18.8514 8.60737 17.1309 8.09122 15.0085 8.09122C12.8861 8.09122 11.1656 8.60737 11.1656 9.24409C11.1656 9.8808 12.8861 10.397 15.0085 10.397Z"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
              />
              <path
                d="M18.8514 9.22168C18.8514 13.8676 18.8514 15.7038 18.8514 20.3497C18.8514 20.8341 17.1369 21.1571 15.0085 21.1571C12.8801 21.1571 11.1656 20.8341 11.1656 20.3497V9.22168"
                stroke="#34303D"
                stroke-width="1.51351"
              />
              <path
                d="M22.7061 24.2437C22.7061 25.2587 23.5502 26.1028 24.5652 26.1028C25.6002 26.1028 26.4243 25.2385 26.4243 24.1977V13.0365C26.4243 12.0214 25.5803 11.1774 24.5652 11.1774C23.5502 11.1774 22.7061 12.0214 22.7061 13.0365V24.2437Z"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
              />
              <path
                d="M24.5654 26.8832V36.5747"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M36.6417 24.2437C36.6417 25.2587 37.4857 26.1028 38.5008 26.1028C39.5358 26.1028 40.3599 25.2385 40.3599 24.1977V13.0365C40.3599 12.0214 39.5158 11.1774 38.5008 11.1774C37.4857 11.1774 36.6417 12.0214 36.6417 13.0365V24.2437Z"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
              />
              <path
                d="M38.5005 26.8832V36.5747"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M29.6967 24.2437C29.6967 25.2587 30.5408 26.1028 31.5558 26.1028C32.5908 26.1028 33.4149 25.2385 33.4149 24.1977V13.0365C33.4149 12.0214 32.5709 11.1774 31.5558 11.1774C30.5408 11.1774 29.6967 12.0214 29.6967 13.0365V24.2437Z"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
              />
              <path
                d="M31.5559 26.8832V38.2741"
                stroke="#34303D"
                stroke-width="1.51351"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              className={styles.bubbleShine}
            >
              <path
                d="M36.8661 55.9352C38.5761 56.2339 39.7326 57.8801 39.4338 59.5901C39.135 61.3002 37.4889 62.4567 35.7788 62.1579C34.0688 61.8591 32.9123 60.213 33.2111 58.5029C33.514 56.7691 35.1323 55.6322 36.8661 55.9352Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                d="M25.9566 60.7704C29.8309 61.055 31.4845 57.5934 27.73 55.1708C24.3266 52.9637 19.4183 48.1316 15.7308 41.817C13.3474 37.7691 6.91666 41.8026 9.87407 48.5896C11.1826 51.594 18.7706 60.2121 25.9566 60.7704Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                opacity="0.3"
                d="M48.9018 17.9179C55.2966 23.6846 54.0277 34.68 59.6547 33.4283C64.3949 32.3514 61.8923 18.9746 54.374 12.0879C48.1261 6.38852 35.1618 4.67958 34.9468 10.4526C34.7834 14.897 42.3076 11.9649 48.9018 17.9179Z"
                fill="#F9FFFF"
              />
            </svg>
          </div>
          <p className={green ? styles.greenText : styles.answerText}>
            Палочки и устройство для чистки
          </p>
        </button>
        <button
          className={getStyle1()}
          onClick={() => {
            handleSecondAnswer();
          }}
        >
          <div className={red ? styles.redBubble : styles.bubble}>
            <img src={icon} alt="" className={styles.icon} />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              className={styles.bubbleShine}
            >
              <path
                d="M36.8661 55.9352C38.5761 56.2339 39.7326 57.8801 39.4338 59.5901C39.135 61.3002 37.4889 62.4567 35.7788 62.1579C34.0688 61.8591 32.9123 60.213 33.2111 58.5029C33.514 56.7691 35.1323 55.6322 36.8661 55.9352Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                d="M25.9566 60.7704C29.8309 61.055 31.4845 57.5934 27.73 55.1708C24.3266 52.9637 19.4183 48.1316 15.7308 41.817C13.3474 37.7691 6.91666 41.8026 9.87407 48.5896C11.1826 51.594 18.7706 60.2121 25.9566 60.7704Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                opacity="0.3"
                d="M48.9018 17.9179C55.2966 23.6846 54.0277 34.68 59.6547 33.4283C64.3949 32.3514 61.8923 18.9746 54.374 12.0879C48.1261 6.38852 35.1618 4.67958 34.9468 10.4526C34.7834 14.897 42.3076 11.9649 48.9018 17.9179Z"
                fill="#F9FFFF"
              />
            </svg>
          </div>
          <p className={red ? styles.redText : styles.answerText}>
            Зубная щетка и спица
          </p>
        </button>
        <button
          className={getStyle2()}
          onClick={() => {
            handleThierdAnswer();
          }}
        >
          <div className={red1 ? styles.redBubble : styles.bubble}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="47"
              viewBox="0 0 35 47"
              fill="none"
              className={styles.icon}
            >
              <path
                d="M5.35881 25.5658L18.649 4.85233C20.016 2.7218 24.2358 -0.58591 30.1792 3.2275C36.1226 7.0409 33.5927 11.3472 32.1868 13.5384L13.3799 42.8499C12.7061 43.9 8.88845 47.3801 4.39789 44.4988C-0.0926765 41.6176 1.80991 36.9088 2.48367 35.8587L17.4933 12.4653C18.6539 10.6565 21.6135 8.84944 25.0474 11.0527C28.4814 13.256 27.067 16.7957 26.2433 18.0795L14.472 36.4257"
                stroke="#34303D"
                stroke-width="1.28049"
                stroke-linecap="round"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              className={styles.bubbleShine}
            >
              <path
                d="M36.8661 55.9352C38.5761 56.2339 39.7326 57.8801 39.4338 59.5901C39.135 61.3002 37.4889 62.4567 35.7788 62.1579C34.0688 61.8591 32.9123 60.213 33.2111 58.5029C33.514 56.7691 35.1323 55.6322 36.8661 55.9352Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                d="M25.9566 60.7704C29.8309 61.055 31.4845 57.5934 27.73 55.1708C24.3266 52.9637 19.4183 48.1316 15.7308 41.817C13.3474 37.7691 6.91666 41.8026 9.87407 48.5896C11.1826 51.594 18.7706 60.2121 25.9566 60.7704Z"
                fill="#56E7F8"
                fill-opacity="0.67"
              />
              <path
                opacity="0.3"
                d="M48.9018 17.9179C55.2966 23.6846 54.0277 34.68 59.6547 33.4283C64.3949 32.3514 61.8923 18.9746 54.374 12.0879C48.1261 6.38852 35.1618 4.67958 34.9468 10.4526C34.7834 14.897 42.3076 11.9649 48.9018 17.9179Z"
                fill="#F9FFFF"
              />
            </svg>
          </div>
          <p className={red1 ? styles.redText : styles.answerText}>
            Канцелярская скрепка
          </p>
        </button>
      </div>
      {red || red1 || green ? (
        <></>
      ) : (
        <button className={styles.button} onClick={checkAnswer}>
          <p className={styles.buttonText}>Ответить</p>
        </button>
      )}
      {modal && <FirstQuestionModal openSecond={openSecond} />}
      {errorModal && <FirstQuestionErrorModal openSecond={openSecond} />}
    </div>
  );
}
