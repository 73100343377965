import React from 'react';
import styles from './Guide.module.css';
import question from '../../images/question.png';
import question1 from '../../images/question1.png';

export default function Guide({ openTools }) {
  return (
    <div className={styles.guide}>
      <div>
        <h1 className={styles.title}>Раз - два - три — чисто!</h1>
        <p className={styles.text}>
          Мы подготовили для вас увлекательный квиз по чистке.
        </p>
      </div>

      <div>
        <div className={styles.imgContainer}>
          <img src={question} alt="" className={styles.img} />
          <img src={question1} alt="" className={styles.img} />
        </div>

        <p className={styles.bottomText}>
          Готовы проверить, насколько хорошо вы заботитесь о своем устройстве?
        </p>

        <button className={styles.button} onClick={openTools}>
          <p className={styles.buttonText}>Начать</p>
        </button>
      </div>
    </div>
  );
}
