import React from "react";
import styles from "./Tools.module.css";
import solid from "../../images/lil_SOLID.png";
import solid1 from "../../images/lil_SOLID_2.png";
import duo from "../../images/IQOS_Duo.png";
import duo1 from "../../images/IQOS_Duo1.png";

export default function Tools({ openFirst, setIqos }) {
  return (
    <div className={styles.tools}>
      <h1 className={styles.title}>Какое у вас устройство?</h1>

      <button
        className={styles.container}
        onClick={() => {
          openFirst();
          setIqos(false);
        }}
      >
        <p className={styles.name}>lil SOLID</p>
        <div className={styles.button}>
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path d="M4.25 17L28.3333 17" stroke="#34303D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M22.6666 8.5L26.6411 12.4745C28.2252 14.0586 29.0172 14.8506 29.314 15.7639C29.575 16.5673 29.575 17.4327 29.314 18.2361C29.0172 19.1494 28.2252 19.9414 26.6411 21.5255L22.6666 25.5"
              stroke="#34303D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <img src={solid} alt="" className={styles.solid} />
        <img src={solid1} alt="" className={styles.solid1} />
      </button>
      <button
        className={styles.container}
        onClick={() => {
          openFirst();
          setIqos(true);
        }}
      >
        <p className={styles.name}>IQOS</p>
        <div className={styles.button}>
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path d="M4.25 17L28.3333 17" stroke="#34303D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M22.6666 8.5L26.6411 12.4745C28.2252 14.0586 29.0172 14.8506 29.314 15.7639C29.575 16.5673 29.575 17.4327 29.314 18.2361C29.0172 19.1494 28.2252 19.9414 26.6411 21.5255L22.6666 25.5"
              stroke="#34303D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <img src={duo} alt="" className={styles.duo} />
        <img src={duo1} alt="" className={styles.duo1} />
      </button>
    </div>
  );
}
