import React, { useEffect, useState } from 'react';
import styles from './SecondQuestionIqos.module.css';
import SecondQuestionErrorModal from '../Modals/SecondQuestionErrorModal';
import SecondQuestionModal from '../Modals/SecondQuestionModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import lil from '../../images/lil.svg';
import stars from '../../images/stars.svg';
import iqosImg from '../../images/iqos.svg';
import term from '../../images/termometr.svg';

export default function SecondQuestionIqos({ openThierd, iqos }) {
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [itemList, setItemList] = useState([
    { img: term, text: 'Дождаться остывания нагревательного элемента' },
    { img: iqos ? iqosImg : lil, text: 'Снять колпачок' },
    { img: stars, text: 'Почистить держатель и колпачок' },
  ]);

  const [green, setGreen] = useState(false);
  const [green1, setGreen1] = useState(false);
  const [green2, setGreen2] = useState(false);
  const [red, setRed] = useState(false);
  const [red1, setRed1] = useState(false);
  const [red2, setRed2] = useState(false);

  const defaultList = [
    { img: term, text: 'Дождаться остывания нагревательного элемента' },
    { img: iqos ? iqosImg : lil, text: 'Снять колпачок' },
    { img: stars, text: 'Почистить держатель и колпачок' },
  ];

  useEffect(() => {
    while (JSON.stringify(defaultList) === JSON.stringify(itemList)) {
      setItemList(shuffle(defaultList));
    }
  }, []);

  const shuffle = (array) => {
    let m = array.length,
      t,
      i;

    // Пока есть элементы для перемешивания
    while (m) {
      // Взять оставшийся элемент
      i = Math.floor(Math.random() * m--);

      // И поменять его местами с текущим элементом
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  };

  const checkAnswer = () => {
    console.log(defaultList);
    console.log(itemList);
    if (JSON.stringify(defaultList) === JSON.stringify(itemList)) {
      setGreen(true);
      setGreen1(true);
      setGreen2(true);
      setTimeout(() => {
        setModal(true);
      }, 2000);
    } else {
      if (itemList[0].text === 'Дождаться остывания нагревательного элемента') {
        setGreen(true);
      } else {
        setRed(true);
      }

      if (itemList[1].text === 'Снять колпачок') {
        setGreen1(true);
      } else {
        setRed1(true);
      }
      if (itemList[2].text === 'Почистить держатель и колпачок') {
        setGreen2(true);
      } else {
        setRed2(true);
      }

      setTimeout(() => {
        setErrorModal(true);
      }, 2000);
    }
  };

  const getContainerStyle = (index, isDragging) => {
    if (isDragging) {
      return styles.dragAnswerContainer;
    }
    if (index === 0) {
      if (green) {
        return styles.successAnswerContainer;
      } else if (red) {
        return styles.errorAnswerContainer;
      } else {
        return styles.answerContainer;
      }
    }
    if (index === 1) {
      if (green1) {
        return styles.successAnswerContainer;
      } else if (red1) {
        return styles.errorAnswerContainer;
      } else {
        return styles.answerContainer;
      }
    }
    if (index === 2) {
      if (green2) {
        return styles.successAnswerContainer;
      } else if (red2) {
        return styles.errorAnswerContainer;
      } else {
        return styles.answerContainer;
      }
    }
  };
  const getTextStyle = (index) => {
    if (index === 0) {
      if (green) {
        return styles.successAnswerText;
      } else if (red) {
        return styles.errorAnswerText;
      } else {
        return styles.answerText;
      }
    }
    if (index === 1) {
      if (green1) {
        return styles.successAnswerText;
      } else if (red1) {
        return styles.errorAnswerText;
      } else {
        return styles.answerText;
      }
    }
    if (index === 2) {
      if (green2) {
        return styles.successAnswerText;
      } else if (red2) {
        return styles.errorAnswerText;
      } else {
        return styles.answerText;
      }
    }
  };

  const getBubbleStyle = (index) => {
    if (index === 0) {
      if (green) {
        return styles.successBubble;
      } else if (red) {
        return styles.errorBubble;
      } else {
        return styles.bubble;
      }
    }
    if (index === 1) {
      if (green1) {
        return styles.successBubble;
      } else if (red1) {
        return styles.errorBubble;
      } else {
        return styles.bubble;
      }
    }
    if (index === 2) {
      if (green2) {
        return styles.successBubble;
      } else if (red2) {
        return styles.errorBubble;
      } else {
        return styles.bubble;
      }
    }
  };

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };
  return (
    <div className={styles.second}>
      <div>
        <div className={styles.navbar}>
          <div className={styles.nav} style={{ background: '#26D7EE' }}></div>
          <div className={styles.nav} style={{ background: '#26D7EE' }}></div>
          <div className={styles.nav}></div>
        </div>
        <h2 className={styles.title}>
          Перетягивая блоки, расположите в правильной последовательности
        </h2>

        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div
                className={styles.container}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {itemList &&
                  itemList.map((item, index) => (
                    <Draggable
                      key={item.text}
                      draggableId={item.text}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={getContainerStyle(
                            index,
                            snapshot.isDragging
                          )}
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <div className={getBubbleStyle(index)}>
                            <img
                              src={item.img}
                              alt=""
                              className={styles.icon}
                            />

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="70"
                              height="70"
                              viewBox="0 0 70 70"
                              fill="none"
                              className={styles.bubbleShine}
                            >
                              <path
                                d="M36.8661 55.9352C38.5761 56.2339 39.7326 57.8801 39.4338 59.5901C39.135 61.3002 37.4889 62.4567 35.7788 62.1579C34.0688 61.8591 32.9123 60.213 33.2111 58.5029C33.514 56.7691 35.1323 55.6322 36.8661 55.9352Z"
                                fill="#56E7F8"
                                fill-opacity="0.67"
                              />
                              <path
                                d="M25.9566 60.7704C29.8309 61.055 31.4845 57.5934 27.73 55.1708C24.3266 52.9637 19.4183 48.1316 15.7308 41.817C13.3474 37.7691 6.91666 41.8026 9.87407 48.5896C11.1826 51.594 18.7706 60.2121 25.9566 60.7704Z"
                                fill="#56E7F8"
                                fill-opacity="0.67"
                              />
                              <path
                                opacity="0.3"
                                d="M48.9018 17.9179C55.2966 23.6846 54.0277 34.68 59.6547 33.4283C64.3949 32.3514 61.8923 18.9746 54.374 12.0879C48.1261 6.38852 35.1618 4.67958 34.9468 10.4526C34.7834 14.897 42.3076 11.9649 48.9018 17.9179Z"
                                fill="#F9FFFF"
                              />
                            </svg>
                          </div>
                          <p className={getTextStyle(index)}>{item.text}</p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {green || red || red1 || red2 ? (
        <></>
      ) : (
        <button className={styles.button} onClick={checkAnswer}>
          <p className={styles.buttonText}>Ответить</p>
        </button>
      )}

      {modal && <SecondQuestionModal openThierd={openThierd} />}
      {errorModal && <SecondQuestionErrorModal openThierd={openThierd} />}
    </div>
  );
}
